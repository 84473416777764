"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.App = void 0;

var _react = _interopRequireDefault(require("react"));

var _AppContext = require("../../contexts/appContext/AppContext");

var _ = require("../");

var _styles = require("@material-ui/styles");

var _misc = require("../../misc");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var App = function App(props) {
  document.title = props.title;
  _misc.theme.palette.primary = props.primaryColor;
  _misc.theme.palette.background.primary = props.primaryBackgroundColor;
  _misc.theme.palette.background.secondary = props.secondaryBackgroundColor;
  return _react.default.createElement(_AppContext.AppContextProvider, Object.assign({}, props), _react.default.createElement(_styles.ThemeProvider, {
    theme: _misc.theme
  }, _react.default.createElement(_.Layout, null, _react.default.createElement(_.Tutor, null))));
};

exports.App = App;