"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = void 0;

var _core = require("@material-ui/core");

var useStyles = (0, _core.makeStyles)(function () {
  return {
    container: {
      minHeight: "100vh"
    }
  };
});
exports.useStyles = useStyles;