"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = void 0;

var _styles = require("@material-ui/styles");

var useStyles = (0, _styles.makeStyles)(function () {
  return {
    brand: {
      flexGrow: 1
    }
  };
});
exports.useStyles = useStyles;