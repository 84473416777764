"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _App = require("./app/App");

Object.keys(_App).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _App[key];
    }
  });
});

var _Layout = require("./layout/Layout");

Object.keys(_Layout).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _Layout[key];
    }
  });
});

var _Header = require("./header/Header");

Object.keys(_Header).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _Header[key];
    }
  });
});

var _Footer = require("./footer/Footer");

Object.keys(_Footer).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _Footer[key];
    }
  });
});

var _Routes = require("./routes/Routes");

Object.keys(_Routes).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _Routes[key];
    }
  });
});

var _Brand = require("./brand/Brand");

Object.keys(_Brand).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _Brand[key];
    }
  });
});

var _Tutor = require("./tutor/Tutor");

Object.keys(_Tutor).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _Tutor[key];
    }
  });
});