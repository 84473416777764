"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Header = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Header = function Header() {
  return _react.default.createElement(_react.default.Fragment, null) // <AppBar position="sticky">
  // 	<Container>
  // 		<Toolbar>
  // 			<Brand />
  // 		</Toolbar>
  // 	</Container>
  // </AppBar>
  ;
};

exports.Header = Header;