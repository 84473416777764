"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Layout = void 0;

var _react = _interopRequireDefault(require("react"));

var _ = require("./..");

var _Layout = require("./Layout.styles");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Layout = function Layout(props) {
  var classes = (0, _Layout.useStyles)();
  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_.Header, null), _react.default.createElement("div", {
    className: classes.container
  }, props.children), _react.default.createElement(_.Footer, null));
};

exports.Layout = Layout;