"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Tutor = void 0;

var _react = _interopRequireWildcard(require("react"));

var _core = require("@material-ui/core");

var _Tutor = require("./Tutor.styles");

var _Star = _interopRequireDefault(require("@material-ui/icons/Star"));

var _AppContext = require("../../contexts/appContext/AppContext");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};

  if (obj != null) {
    var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

        if (desc && (desc.get || desc.set)) {
          Object.defineProperty(newObj, key, desc);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var Tutor = function Tutor() {
  var appContext = (0, _react.useContext)(_AppContext.AppContext);
  var classes = (0, _Tutor.useStyles)(appContext);
  return _react.default.createElement("div", {
    className: classes.container
  }, _react.default.createElement("div", {
    className: classes.row
  }, _react.default.createElement(_core.Grid, {
    container: true,
    spacing: 4,
    alignItems: "center",
    justify: "center"
  }, _react.default.createElement(_core.Hidden, {
    mdDown: true
  }, _react.default.createElement(_core.Grid, {
    item: true,
    xs: 12,
    md: "auto"
  }, _react.default.createElement("img", {
    alt: "",
    src: appContext.topicIconUrl,
    className: classes.avatar
  }))), _react.default.createElement(_core.Grid, {
    item: true,
    xs: 12,
    md: "auto"
  }, _react.default.createElement(_core.Typography, {
    variant: "h3",
    align: "center",
    gutterBottom: true
  }, "Need help with ", appContext.topic, "?"), _react.default.createElement(_core.Typography, {
    variant: "h5",
    align: "center"
  }, "I will consult or tutor you for free")), _react.default.createElement(_core.Hidden, {
    mdDown: true
  }, _react.default.createElement(_core.Grid, {
    item: true,
    xs: 12,
    md: "auto"
  }, _react.default.createElement(_core.Avatar, {
    src: appContext.arkuniAvatarUrl,
    className: classes.avatar
  }))), _react.default.createElement(_core.Hidden, {
    lgUp: true
  }, _react.default.createElement(_core.Grid, {
    item: true,
    xs: 12
  }, _react.default.createElement(_core.Grid, {
    container: true,
    spacing: 4,
    alignItems: "center",
    justify: "center"
  }, _react.default.createElement(_core.Grid, {
    item: true
  }, _react.default.createElement("img", {
    alt: "",
    src: appContext.topicIconUrl,
    className: classes.avatar
  })), _react.default.createElement(_core.Grid, {
    item: true
  }, _react.default.createElement(_core.Avatar, {
    src: appContext.arkuniAvatarUrl,
    className: classes.avatar
  }))))))), _react.default.createElement("div", {
    className: classes.row
  }, _react.default.createElement(_core.Grid, {
    container: true,
    spacing: 4,
    justify: "center",
    alignItems: "center"
  }, _react.default.createElement(_core.Grid, {
    item: true,
    xs: 12,
    md: "auto"
  }, _react.default.createElement(_core.Box, {
    textAlign: "center"
  }, _react.default.createElement(_core.Tooltip, {
    title: appContext.email,
    classes: {
      tooltip: classes.tooltip
    }
  }, _react.default.createElement(_core.Button, {
    variant: "contained",
    color: "primary",
    className: classes.ctaButton,
    href: "mailto: ".concat(appContext.email)
  }, "Send an email")))))), _react.default.createElement("div", {
    className: classes.row
  }, _react.default.createElement(_core.Typography, {
    variant: "h4",
    gutterBottom: true
  }, "Ask for things like:"), _react.default.createElement(_core.List, {
    className: classes.list
  }, appContext.highlights.map(function (highlight, i) {
    return _react.default.createElement(_core.ListItem, {
      key: i
    }, _react.default.createElement(_core.ListItemIcon, null, _react.default.createElement(_Star.default, null)), _react.default.createElement(_core.ListItemText, null, highlight));
  }))));
};

exports.Tutor = Tutor;