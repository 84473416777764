"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Brand = void 0;

var _react = _interopRequireWildcard(require("react"));

var _core = require("@material-ui/core");

var _AppContext = require("../../contexts/appContext/AppContext");

var _Brand = require("./Brand.styles");

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};

  if (obj != null) {
    var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

        if (desc && (desc.get || desc.set)) {
          Object.defineProperty(newObj, key, desc);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var Brand = function Brand(props) {
  var appContext = (0, _react.useContext)(_AppContext.AppContext);
  var classes = (0, _Brand.useStyles)();
  return _react.default.createElement(_core.Typography, {
    variant: "h4",
    className: classes.brand
  }, appContext.title);
};

exports.Brand = Brand;