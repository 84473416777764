"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Routes = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactRouter = require("react-router");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Routes = function Routes() {
  return _react.default.createElement(_reactRouter.Switch, null, _react.default.createElement(_reactRouter.Route, {
    exact: true
  }, _react.default.createElement(_react.default.Fragment, null)));
};

exports.Routes = Routes;