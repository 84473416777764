"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.theme = void 0;

var _core = require("@material-ui/core");

var overrideTheme = (0, _core.createMuiTheme)({});
overrideTheme.overrides = {
  MuiTooltip: {
    tooltip: {
      backgroundColor: "white",
      color: "black",
      fontSize: 16
    }
  }
};
var theme = overrideTheme;
exports.theme = theme;