"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = void 0;

var _core = require("@material-ui/core");

var useStyles = (0, _core.makeStyles)(function (theme) {
  return {
    container: {
      minHeight: "inherit",
      backgroundColor: theme.palette.background.primary,
      color: theme.palette.common.white,
      display: "flex",
      flexDirection: "column",
      "& > div:nth-child(2)": {
        backgroundColor: theme.palette.background.secondary
      },
      "& > div:last-child": {
        flexGrow: 1
      },
      "& .MuiSvgIcon-root": {
        color: theme.palette.common.white
      }
    },
    row: {
      padding: 64,
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    avatar: {
      width: 128,
      height: 128
    },
    ctaButton: {
      fontSize: 32
    },
    list: {},
    tooltip: {
      fontSize: 24
    }
  };
});
exports.useStyles = useStyles;